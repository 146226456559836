'use client';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
export default function MailtoButton({
  className
}: {
  className?: string;
}) {
  const handleMailto = () => {
    const subject = 'Fehler in meinen Bestandsdaten';
    const body = 'Sehr geehrte Damen und Herren. Bei der Vervollständigung meines Antrags von Aareal Aval ist mir folgender Fehler in den Bestandsdaten aufgefallen:';
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };
  return <Button className={cn('m-auto sm:m-0 rounded-md bg-gradient-to-r from-[#002A5F] to-[#1E3E8E] px-12 py-2.5 text-sm font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#6078E2] hover:from-primary hover:to-blue-900  drop-shadow-md', className)} onClick={handleMailto} data-sentry-element="Button" data-sentry-component="MailtoButton" data-sentry-source-file="mail-button.tsx">
      Vermieter kontaktieren
    </Button>;
}