import { clsx, type ClassValue } from 'clsx';
import { parseISO, format } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { UserData } from '@/lib/types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function transformUserData(userData: unknown): UserData {
  if (typeof userData !== 'object' || userData === null) {
    throw new Error('Invalid userData');
  }

  const firstTenant =
    typeof userData === 'object' &&
    userData !== null &&
    'firstTenant' in userData &&
    typeof (userData as Record<string, unknown>).firstTenant === 'object'
      ? ((userData as Record<string, unknown>).firstTenant as Record<string, unknown>)
      : {};

  return {
    ...userData,
    firstTenant: {
      ...firstTenant,
      phoneNumber: firstTenant.phoneNumber
        ? (firstTenant.phoneNumber as string).trim().replace(/^0/, '+49')
        : '',
      dateOfBirth: firstTenant.dateOfBirth
        ? format(parseISO(firstTenant.dateOfBirth as string), 'dd.MM.yyyy')
        : '',
    },
  } as unknown as UserData;
}

export function getBaseUrl(): string {
  switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    case 'development':
      return 'https://dev.aareal-aval.de';
    case 'staging':
      return 'https://stage.aareal-aval.de';
    case 'production':
      return 'https://aareal-aval.de';
    default:
      return 'https://aareal-aval.de';
  }
}

export function getBackendUrl(): string {
  switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    case 'development':
      return 'https://api.dev.aareal-aval.de';
    case 'staging':
      return 'https://api.stage.aareal-aval.de';
    case 'production':
      return 'https://api.aareal-aval.de';
    default:
      return 'http://local.api.aareal-aval.de';
  }
}

export function sendData(data: UserData) {
  console.log('Send data to backend: ', data);
  return null;
}

export function mergeRefs<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return (node: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = node;
      }
    });
  };
}
